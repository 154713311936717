<template>
  <div class="body1">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'hotel_index'}">{{ $t('hotel_index') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ propKeywords }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div style="margin:20px auto;">
      <hotel-search ref="hotel_search" @searchGo="searchGo" :prop-keywords="propKeywords"></hotel-search>
    </div>
    <div class="list-filter-items">
      <a-row v-if="areaOptions.length > 0 || pointOptions.length > 0">
        <a-col :span="3" class="list-filter-items-title">
          {{ $ta('hotel|filters') }}
          <a-divider type="vertical" style="height: 100%;background-color: #ECECEC;"/>
        </a-col>
        <a-col :span="21">
          <a-tabs v-model:activeKey="hotelLoctionTabKey" size="small" :tabBarStyle="{ marginTop: '-8px'}">
            <a-tab-pane key="area" v-if="areaOptions.length > 0">
              <template #tab>
                <a-badge :dot="areas.length > 0">
                  {{$t('neighborhood')}}
                </a-badge>
              </template>
              <a-checkbox-group v-model:value="areas">
                <div class="location-container">
                  <template v-for="(item) in areaOptions.filter((a,i) => i < maxOptionNum)" v-bind:key="item.value">
                    <div class="location-container-item">
                      <a-checkbox :value="item.value">{{ item.label }}</a-checkbox>
                    </div>
                  </template>
                  <div class="location-container-item" v-if="areaOptions.length > maxOptionNum">
                    <a-popover placement="bottomLeft" v-model:visible="areaOptionMoreVisible" trigger="click">
                      <template #title>
                        <div class="more-option-title">
                          <a-input-search v-model:value="areaSearchLabel" style="width: 300px"/>
                          <CloseOutlined @click="areaOptionMoreVisible=false;areaSearchLabel='';" />
                        </div>
                      </template>
                      <template #content>
                        <a-checkbox-group v-model:value="areas">
                          <div class="more-option-content">
                            <template v-for="(item,index) in areaOptions.filter(ai => ai.label.toLowerCase().indexOf(areaSearchLabel.toLowerCase()) >= 0)" v-bind:key="item.value">
                              <div v-if="index >= maxOptionNum || !isEmpty(areaSearchLabel)">
                                <a-checkbox :value="item.value">{{ item.label }}</a-checkbox>
                              </div>
                            </template>
                          </div>
                        </a-checkbox-group>
                      </template>
                      <a>{{ $t('more') }} <DownOutlined/></a>
                    </a-popover>
                  </div>
                </div>
              </a-checkbox-group>
            </a-tab-pane>
            <a-tab-pane key="point" v-if="pointOptions.length > 0">
              <template #tab>
                <a-badge :dot="points.length > 0">
                  {{$t('point_of_interest')}}
                </a-badge>
              </template>
              <a-checkbox-group v-model:value="points" style="width:100%">
                <div class="location-container">
                  <template v-for="(item) in pointOptions.filter((a,i) => i < maxOptionNum)" v-bind:key="item.value">
                    <div class="location-container-item">
                      <a-checkbox :value="item.value">{{ item.label }}</a-checkbox>
                    </div>
                  </template>
                  <div class="location-container-item" v-if="pointOptions.length > maxOptionNum">
                    <a-popover placement="bottomLeft" v-model:visible="pointOptionMoreVisible" trigger="click">
                      <template #title>
                        <div class="more-option-title">
                          <a-input-search v-model:value="areaSearchLabel" style="width: 300px"/>
                          <CloseOutlined @click="pointOptionMoreVisible=false;" />
                        </div>
                      </template>
                      <template #content>
                        <a-checkbox-group v-model:value="points">
                          <div class="more-option-content">
                            <template v-for="(item,index) in pointOptions.filter(ai => ai.label.toLowerCase().indexOf(areaSearchLabel.toLowerCase()) >= 0)" v-bind:key="item.value">
                              <div v-if="index >= maxOptionNum || !isEmpty(areaSearchLabel)">
                                <a-checkbox :value="item.value">{{ item.label }}</a-checkbox>
                              </div>
                            </template>
                          </div>
                        </a-checkbox-group>
                      </template>
                      <a>{{ $t('more') }} <DownOutlined/></a>
                    </a-popover>
                  </div>
                </div>
              </a-checkbox-group>
            </a-tab-pane>
            <a-tab-pane key="chain" v-if="chainOptions.length > 0">
              <template #tab>
                <a-badge :dot="chains.length > 0">
                  {{$ta('hotel|brand')}}
                </a-badge>
              </template>
              <a-checkbox-group v-model:value="chains" style="width:100%">
                <div class="location-container">
                  <template v-for="item in chainOptions" v-bind:key="item.value">
                    <div class="location-container-item">
                      <a-checkbox :value="item.value">{{ item.label }}</a-checkbox>
                    </div>
                  </template>
                </div>
              </a-checkbox-group>
            </a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>
      <a-divider v-if="areaOptions.length > 0 || pointOptions.length > 0"/>
      <a-row>
        <a-col :span="3" class="list-filter-items-title">
          <strong class="list-filter-items-span">
            {{ $t('star') }}
            <a-popover>
              <template #title v-if="isZhCn">
                <strong>酒店星级说明</strong>
              </template>
              <template #title v-else>
                <strong>Star rating description</strong>
              </template>
              <template #content v-if="isZhCn">
                <div style="width: 300px">温馨提示：几度旅行针对酒店为您标记的星级，仅供预订时参考，1-5星均为行业或供应商所提供。正常情况下海外酒店的设施标准可能低于同星级的国内酒店，请在预订前详细参考酒店各方面信息，敬请理解。
                </div>
              </template>
              <template #content v-else>
                <div style="width: 300px">Notice: Please note that star ratings for hotels are only for your reference. 1 to 5 star ratings are provided by 3rd parties. In normal cases, for the same star rating, facility standard of overseas hotels can be lower than domestic hotels. Please refer to the detailed hotel information before make the booking. Sorry for any inconvenience caused.
                </div>
              </template>
              <QuestionCircleOutlined class="warning-color" />
            </a-popover>
          </strong>
          <a-divider type="vertical" style="height: 100%;background-color: #ECECEC;"/>
        </a-col>
        <a-col :span="21">
          <a-checkbox-group v-model:value="stars" style="width:100%">
            <div class="location-container">
              <div class="location-container-item-1">
                <a-button size="small" :type="stars.length > 0 ? 'link':'primary'" @click="stars=[]">{{ $t('no_limit') }}</a-button>
              </div>
              <template v-for="(item) in starOptions" v-bind:key="item.value">
                <div class="location-container-item-1">
                  <a-checkbox :value="item.value">{{ item.label }}</a-checkbox>
                </div>
              </template>
            </div>
          </a-checkbox-group>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col :span="3" class="list-filter-items-title">
          {{ $t('guest_rating') }}
          <a-divider type="vertical" style="height: 100%;background-color: #ECECEC;"/>
        </a-col>
        <a-col :span="21">
          <a-radio-group v-model:value="guestRating">
            <div class="location-container">
              <div class="location-container-item-1">
                <a-button size="small" :type="guestRating ? 'link':'primary'" @click="guestRating=null">{{ $t('no_limit') }}</a-button>
              </div>
              <template v-for="(item) in guestRatingOptions" v-bind:key="item.value">
                <div class="location-container-item-1">
                  <a-radio :value="item.value">{{ item.label }}</a-radio>
                </div>
              </template>
            </div>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col :span="3" class="list-filter-items-title">
          <span style="word-break: break-word;">{{ $t('price_room_night') }}({{ this.$store.getters['setting/getCurrency'] }})</span>
          <a-divider type="vertical" style="height: 100%;background-color: #ECECEC;"/>
        </a-col>
        <a-col :span="21" style="display: flex;align-items: center;">
          <a-radio-group v-model:value="priceRange" @change="priceRangeS='';priceRangeE='';">
            <div class="location-container">
              <div class="location-container-item-1">
                <a-button size="small" :type="priceRange ? 'link':'primary'" @click="priceRange=null;priceRangeS='';priceRangeE='';">{{ $t('no_limit') }}</a-button>
              </div>
              <template v-for="(item,index) in priceRangeOptions[this.$store.getters['setting/getCurrency']]" v-bind:key="index">
                <div class="location-container-item-1">
                  <a-radio :value="item.value">{{ item.label }}</a-radio>
                </div>
              </template>
              <div class="location-container-item-1">
                <a-input-group compact>
                  <a-input-number v-model:value="priceRangeS" size="small" :min="1" style="width:80px" /> <span>~</span> <a-input-number v-model:value="priceRangeE" size="small" :min="priceRangeS + 1" style="width:80px"/>
                  <a-button v-if="priceRangeS || priceRangeE" @click="priceRange=priceRangeS+','+priceRangeE;" size="small">{{ $t('ok') }}</a-button>
                </a-input-group>
              </div>
            </div>
          </a-radio-group>
        </a-col>
      </a-row>
    </div>
    <div class="list-sort">
      <a-space v-if="hasFilters">
        <template v-for="item in areas" v-bind:key="item">
          <a-tag color="#234BA1" closable @close="areas = remove(areas,item)">{{ areaOptions.filter(option => option.value === item)[0].label }}</a-tag>
        </template>
        <template v-for="item in points" v-bind:key="item">
          <a-tag color="#234BA1" closable @close="points = remove(points,item)">{{ pointOptions.filter(option => option.value === item)[0].label }}</a-tag>
        </template>
        <template v-for="item in stars" v-bind:key="item">
          <a-tag color="#234BA1" closable @close="stars = remove(stars,item)">{{ starOptions.filter(option => option.value === item)[0].label }}</a-tag>
        </template>
        <template v-if="guestRating != null">
          <a-tag color="#234BA1" closable @close="guestRating=null;">{{ guestRatingOptions.filter(option => option.value === guestRating)[0].label }}</a-tag>
        </template>
        <template v-for="item in chains" v-bind:key="item">
          <a-tag color="#234BA1" closable @close="chains = remove(chains,item)">{{ chainOptions.filter(option => option.value === item)[0].label }}</a-tag>
        </template>
        <template v-if="priceRange != null">
          <a-tag color="#234BA1" closable @close="priceRange=null;priceRangeE='';priceRangeS='';">{{ this.$store.getters['setting/getCurrency'] }} {{ priceRange.replace(',',' ~ ') }}</a-tag>
        </template>
        <a-button type="link" @click="clearAllFilter">{{ $t('clear_all') }}</a-button>
      </a-space>
      <a-divider v-if="hasFilters" />
      <a-space size="large">
        <span style="font-weight: 600" v-html="$t('hotel_unit', {n:dataPaging.Total})"></span>
        <a-button size="small" :type=" isFavoriteSort === null && isStarSort === null && isGuestRatingSort === null&& isPriceSort === null ?'primary':'link'" @click="isFavoriteSort=null;isStarSort=null;isGuestRatingSort=null;isPriceSort=null;">{{ $t('hot_selling_priority') }}
        </a-button>
        <a-button size="small" :type="isGuestRatingSort === true ?'primary':'link'" @click="isFavoriteSort=null;isStarSort=null;isGuestRatingSort=true;isPriceSort=null;">{{ $t('guest_rating_sort') }}</a-button>
        <a-button size="small" :type="isFavoriteSort === true ?'primary':'link'" @click="isFavoriteSort=true;isStarSort=null;isGuestRatingSort=null;isPriceSort=null;">
          {{ $t('favorite_priority') }}
        </a-button>
        <a-button size="small" :type="isPriceSort === false ?'primary':'link'" @click="isFavoriteSort=null;isStarSort=null;isGuestRatingSort=null;isPriceSort=false;">
          {{ $t('price_lowest') }}
        </a-button>
        <a-button size="small" :type="isPriceSort === true ?'primary':'link'" @click="isFavoriteSort=null;isStarSort=null;isGuestRatingSort=null;isPriceSort=true;">
          {{ $t('price_highest') }}
        </a-button>
        <a-button size="small" :type="isStarSort === true ?'primary':'link'" @click="isFavoriteSort=null;isStarSort=true;isGuestRatingSort=null;isPriceSort=null;">{{ $t('star_highest') }}
        </a-button>
        <a-button size="small" :type="isStarSort === false ?'primary':'link'" @click="isFavoriteSort=null;isStarSort=false;isGuestRatingSort=null;isPriceSort=null;">{{ $t('star_lowest') }}
        </a-button>
      </a-space>
    </div>
    <a-spin :spinning="loading" size="large" style="width:100%;padding: 50px 0px">
      <a-row class="hotel-list" v-if="dataPaging.Rows.length > 0">
        <a-col :span="17">
          <template v-for="(hotel,index) in dataPaging.Rows" v-bind:key="index">
            <a-row class="hotel-list-item" :id="'marker-' + index" @mouseenter="itemMouseEnter(index)" @mouseleave="itemMouseLeave(index)">
              <a-col :span="6" class="hotel-list-item-left"
                     :style="{ backgroundImage: 'url(\'' + getFullImageUrl(hotel.image_url, false) + '\')'}">
                <span v-if="hotel.g_overall" class="guestrating">{{ hotel.g_overall }}</span>
              </a-col>
              <a-col :span="18" class="hotel-list-item-right">
                <p class="hotel-name" v-if="isZHCN">
                  <router-link class="hotel-link-a" target="_blank"
                               :to="{ name:'hotel_detail', params:{HotelId: hotel.hotel_id} ,query: getRouteQuery()}">
                    {{ isEmpty(hotel.hotel_name_cn) ? hotel.hotel_name_en : (hotel.hotel_name_cn + ' (' + hotel.hotel_name_en + ')') }}
                  </router-link>
                  <a-image :preview="false" :src="getMapMarkerIconUrl(index)" :width="30"/>
                </p>
                <p class="hotel-name" v-else>
                  <router-link class="hotel-link-a" target="_blank"
                               :to="{ name:'hotel_detail', params:{HotelId: hotel.hotel_id} ,query: getRouteQuery()}">
                    {{ hotel.hotel_name_en }}
                  </router-link>
                  <a-image :preview="false" :src="getMapMarkerIconUrl(index)" :width="30"/>
                </p>
                <p>
                  <StarFilled class="star-color" v-for="i in parseInt(hotel.hotel_star)" v-bind:key="i"/>
                </p>
                <template v-if="isZHCN">
                  <p>
                    {{ isEmpty(hotel.hotel_address_cn) ? hotel.hotel_address_en : hotel.hotel_address_cn }}
                  </p>
                  <p class="hotel-profile" v-html="getProfile(hotel.hotel_desc_cn)">
                  </p>
                </template>
                <template v-else>
                  <p>
                    {{ hotel.hotel_address_en }}
                  </p>
                  <p class="hotel-profile" v-html="getProfile(hotel.hotel_desc_en)">
                  </p>
                </template>
                <p class="hotel-button">
                  <span v-if="hotel.minprice > 0" class="warning-color" style="font-weight: bolder;margin-right: 10px">{{ hotel.curcode }} {{ $filters.formatAmount(hotel.minprice,0) }}</span>
                  <router-link target="_blank"
                               :to="{ name:'hotel_detail', params:{HotelId: hotel.hotel_id} ,query: getRouteQuery()}">
                    <a-button type="primary">{{ $t('view_details1') }}</a-button>
                  </router-link>
                </p>
              </a-col>
            </a-row>
            <a-divider/>
          </template>
          <div style="text-align: right">
            <a-pagination v-model:current="pageIndex" :total="dataPaging.Total" :showSizeChanger="false"
                          :showQuickJumper="true" :hideOnSinglePage="true"
                          :show-total="total => $t('total_items', {s: total})"/>
          </div>
        </a-col>
        <a-col :span="7" style="padding-left: 10px;" id="map-container-div">
          <div id="map-container" :style="mapContainerStyle"></div>
        </a-col>
      </a-row>
      <a-empty v-if="!loading && dataPaging.Rows.length <= 0" style="width:100%;padding: 50px 0px" />
    </a-spin>
  </div>
</template>

<script>
import {inject, shallowRef} from "vue";
import {DownOutlined, StarFilled, QuestionCircleOutlined, CloseOutlined} from '@ant-design/icons-vue';
import HotelSearch from "@/views/hotel/Search";
import {arrayEquals, isEmpty, getMapMarkerIconUrl, getFullImageUrl, getNameCn, remove} from "@/utils/util";
import {getStarOptions} from "@/data/star";
import {getChainOptions} from "@/data/chain";
import {getHotelListFilters, searchHotels} from "@/services/hotel";
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  name: "HotelList",
  components: {HotelSearch, DownOutlined, StarFilled, QuestionCircleOutlined, CloseOutlined},
  data() {
    const map = shallowRef(null)

    let guest_rating_options = this.$tm('guest_rating_options')
    let guestRatingOptions = []
    for(let key in guest_rating_options) {
        guestRatingOptions.push({ value: key, label: guest_rating_options[key] })
    }
    guestRatingOptions.sort((a,b) =>{
      return b.value - a.value
    })

    return {
      selectedMenuKeys: inject('selectedMenuKeys'),
      isZhCn: this.$store.getters["setting/getLanguage"] === 'zh_CN',
      areaOptionMoreVisible: false,
      pointOptionMoreVisible: false,
      maxOptionNum: 12,
      areaOptions: [],
      pointOptions: [],
      guestRatingOptions,
      priceRangeOptions: {
        'CNY': [
          {value: '0,300', label: '300以下'},
          {value: '300,500', label: '300 ~ 500'},
          {value: '500,800', label: '500 ~ 800'},
          {value: '800,1200', label: '800 ~ 1,200'},
        ],
        'USD': [
          {value: '0,50', label: '50以下'},
          {value: '50,100', label: '50 ~ 100'},
          {value: '100,150', label: '100 ~ 150'},
          {value: '150,250', label: '150 ~ 250'},
        ],
        'JPY': [
          {value: '0,6000', label: '6,000以下'},
          {value: '6000,12000', label: '6,000 ~ 12,000'},
          {value: '12000,18000', label: '12,000 ~ 18,000'},
          {value: '18000,24000', label: '18,000 ~ 24,000'},
        ],
      },
      chainOptions: [],
      areaSearchLabel: '',
      propKeywords: '',
      areas: [],
      points: [],
      stars: [],
      guestRating: null,
      chains: [],
      priceRange: null,
      priceRangeS: '',
      priceRangeE: '',
      isFavoriteSort: null,
      isGuestRatingSort: null,
      isPriceSort: null,
      isStarSort: null,
      pageIndex: 1,
      pageSize: 10,
      dataPaging: { PageIndex:1,PageTotal:0,PageSize:10,Total:0,Rows:[]},
      loading: false,
      map,
      markers:[],
      mapContainerStyle:{ height: '500px', width: '100%', padding: '0px', margin: '0px', backgroundColor: '#e2e9fa' },
      hotelLoctionTabKey: 'area'
    }
  },
  beforeMount() {
    window._AMapSecurityConfig = {
      securityJsCode:'fd923bbc30bb9a6ca9767fa4ccdb79e4',
    }

    if(!(this.$route.params && !isEmpty(this.$route.params.CityCode)))
      this.$router.push({ name: 'home'})

    this.selectedMenuKeys = ['hotel_index']
    this.getFilters(true)
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  unmounted() {
    this.map.destroy()
    window.removeEventListener('scroll',this.handleScroll)
  },
  watch: {
    '$route.params': function (newValue, oldValue) {
      if (newValue.CityCode !== oldValue.CityCode) {
        this.areas = []
        this.points = []
        this.stars = []
        this.guestRating = null
        this.chains = []
        this.priceRange = null
        this.priceRangeS = ''
        this.priceRangeE = ''
        this.isFavoriteSort = null
        this.isGuestRatingSort = null
        this.isPriceSort = null
        this.isStarSort = null
        this.getFilters(true)
      }
    },
    '$route.query': function (newValue, oldValue) {
      if (newValue.CheckIn !== oldValue.CheckIn
          || newValue.CheckOut !== oldValue.CheckOut
          || newValue.Keyword !== oldValue.Keyword) {
        this.reRefreshList()
      }
    },
    areas: function (value, value1) {
      if (!arrayEquals(value, value1)) {
        this.reRefreshList()
      }
    },
    points: function (value, value1) {
      if (!arrayEquals(value, value1)) {
        this.reRefreshList()
      }
    },
    stars: function (value, value1) {
      if (!arrayEquals(value, value1)) {
        this.reRefreshList()
      }
    },
    guestRating: function (value, value1) {
      if (value !== value1) {
        this.reRefreshList()
      }
    },
    chains: function (value, value1) {
      if (!arrayEquals(value, value1)) {
        this.reRefreshList()
      }
    },
    priceRange: function (value, value1) {
      if (value !== value1) {
        this.reRefreshList()
      }
    },
    isFavoriteSort: function (value, value1) {
      if (value !== value1) {
        this.reRefreshList()
      }
    },
    isGuestRatingSort: function (value, value1) {
      if (value !== value1) {
        this.reRefreshList()
      }
    },
    isPriceSort: function (value, value1) {
      if (value !== value1) {
        this.reRefreshList()
      }
    },
    isStarSort: function (value, value1) {
      if (value !== value1) {
        this.reRefreshList()
      }
    },
    pageIndex: function () {
      this.refreshList()
    }
  },
  computed: {
    isZHCN() {
      return this.$store.getters["setting/getLanguage"] === 'zh_CN'
    },
    starOptions() {
      return getStarOptions(this.isZHCN)
    },
    hasFilters(){
      return this.areas.length > 0 || this.points.length > 0 || this.stars.length > 0 || this.chains.length > 0 || this.guestRating != null || this.priceRange != null || this.priceRangeS != '' || this.priceRangeE != ''
    },
  },
  methods: {
    isEmpty,
    getMapMarkerIconUrl,
    getFullImageUrl,
    remove,
    getProfile(desc) {
      if (desc)
        return '<strong style="color: #333333;">' + this.$t('hotel_desc') + ':</strong>&nbsp;&nbsp;' + desc.replace(/<b>[^</>]*<\/b>/g, '').replace(/<br>|<br\/>/g, '')
      else
        return ''
    },
    getFilters(isReloadList) {
      this.loading = true
      getHotelListFilters(this.$route.params.CityCode).then(res => {
        this.loading = false
        this.areaOptions = []
        this.pointOptions = []
        this.chainOptions = []

        const resData = res.data
        if (resData.Success) {
          this.propKeywords = this.isZHCN ? getNameCn(resData.Data.City.name_full_cn, resData.Data.City.name_full_en):resData.Data.City.name_full_en

          if (resData.Data.AreaList) {
            this.areaOptions = resData.Data.AreaList.map(item => {
              return {value: item.id, label: this.isZHCN ? getNameCn(item.name_cn, item.name_en) : item.name_en}
            })

            if(resData.Data.AreaList.filter(item => item.id == this.$route.query.SubCityCode).length > 0)
              this.areas = [this.$route.query.SubCityCode]
          }

          if (resData.Data.PointList) {
            this.pointOptions = resData.Data.PointList.map(item => {
              return {value: item.id, label: this.isZHCN ? getNameCn(item.name_cn, item.name_en) : item.name_en}
            })
            if(resData.Data.PointList.filter(item => item.id == this.$route.query.SubCityCode).length > 0)
              this.points = [this.$route.query.SubCityCode]
          }

          if(resData.Data.Chains) {
            let allChains = getChainOptions(this.isZHCN)
            this.chainOptions = resData.Data.Chains.map(item => {
              return allChains.find(i => i.value === item)
            })
          }

          if(isReloadList)
            this.reRefreshList()
        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
      })
    },
    searchGo(searchData) {
      if (searchData.HotelId > 0) {
        this.$router.push({
          name: 'hotel_detail', params: {HotelId: searchData.HotelId}, query: {
            CheckIn: searchData.CheckIn,
            CheckOut: searchData.CheckOut,
            PaxRoom: JSON.stringify(searchData.PaxRoom),
          }
        })
      } else {
        if (searchData.CityCode !== this.$route.params.CityCode
            || searchData.CheckIn !== this.$route.query.CheckIn
            || searchData.CheckOut !== this.$route.query.CheckOut
            || searchData.Keyword !== this.$route.query.Keyword
            || searchData.SubCityCode !== this.$route.query.SubCityCode) {
          this.$router.push({
            name: 'hotel_list',
            params: {
              CityCode: searchData.CityCode
            },
            query: {
              CheckIn: searchData.CheckIn,
              CheckOut: searchData.CheckOut,
              PaxRoom: JSON.stringify(searchData.PaxRoom),
              Keyword: searchData.Keyword,
              SubCityCode: searchData.SubCityCode
            }
          })
        } else {
          this.reRefreshList()
        }
      }
    },
    refreshList() {
      let searchData = {}
      searchData.CityCode = this.$route.params.CityCode
      searchData.CheckIn = this.$route.query?.CheckIn
      searchData.CheckOut = this.$route.query?.CheckOut
      if(this.$route.query?.PaxRoom)
        searchData.PaxRoom = JSON.parse(this.$route.query?.PaxRoom)
      if(this.$route.query?.Keyword)
        searchData.Keyword = this.$route.query?.Keyword
      this.searchHotel(searchData)
      document.documentElement.scrollIntoView()
    },
    reRefreshList(){
      if (this.pageIndex === 1)
        this.refreshList()
      else
        this.pageIndex = 1
    },
    clearAllFilter(){
      this.areas = []
      this.points = []
      this.stars = []
      this.guestRating = null
      this.chains = []
      this.priceRange = null
      this.priceRangeS = ''
      this.priceRangeE = ''
      this.reRefreshList()
    },
    searchHotel(searchData) {
      let params = {}
      Object.assign(params, searchData)
      params.PageIndex = this.pageIndex
      params.PageSize = this.pageSize
      params.AreaCodes = this.areas.concat(this.points)
      params.Stars = this.stars
      params.guestRating = this.guestRating
      params.Chains = this.chains
      params.CurrenyCode = this.$store.getters["setting/getCurrency"]
      params.PriceRange = this.priceRange
      params.IsFavoriteSort = this.isFavoriteSort
      params.isGuestRatingSort = this.isGuestRatingSort
      params.isPriceSort = this.isPriceSort
      params.IsStarSort = this.isStarSort
      this.loading = true
      searchHotels(params).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.Success) {
          this.dataPaging = resData.Data
          if(this.dataPaging.Rows.length > 0) {
            this.initMap()
            this.addMarkers()
          }else{
            if(this.map)
              this.map.destroy()
          }
        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    initMap() {
      AMapLoader.load({
        key: "449ad181436161774915f814e8bc44b6",
        //version: "2.0",
        plugins: [],
      }).then((AMap) => {
        this.map = new AMap.Map("map-container", {
          lang: this.isZHCN ? 'zh_en' : 'en',
          vectorMapForeign: this.isZHCN ? 'style_zh_cn' : 'style_en',
          viewMode: "2D",
          resizeEnable: true,
          zoom: 10
        })

        let _this = this
        AMap.plugin(['AMap.ToolBar'], function () {
          _this.map.addControl(new AMap.ToolBar({
            liteStyle: true
          }))
        })
      }).catch(e => {
        console.log(e);
      })
    },
    addMarkers(){
      AMapLoader.load({}).then((AMap)=>{
        let markers = []
        this.dataPaging.Rows.forEach((hotel,index) => {
          if(!isEmpty(hotel.hotel_longitude) && !isEmpty(hotel.hotel_latitude)) {
            markers[index] = new AMap.Marker({
              position: new AMap.LngLat(hotel.hotel_longitude, hotel.hotel_latitude),
              offset: new AMap.Pixel(-10, -10),
              icon: getMapMarkerIconUrl(index), // 添加 Icon 图标 URL
            })
            markers[index].index = index
            markers[index].on('click', function(e){
              document.getElementById('marker-' + e.target.index).scrollIntoView({behavior: "smooth"})
            });
          }
        })
        this.map.add(markers)
        this.map.setFitView();
        this.markers = markers
      }).catch((e)=>{
        console.error(e);
      });
    },
    itemMouseEnter(index){
      if(this.markers[index]) {
        this.markers[index].setTop(true)
        this.markers[index].setAnimation('AMAP_ANIMATION_BOUNCE')
      }
    },
    itemMouseLeave(index){
      if(this.markers[index]) {
        this.markers[index].setTop(false)
        this.markers[index].setAnimation('AMAP_ANIMATION_NONE')
      }
    },
    handleScroll(){
      let divRect = document.getElementById('map-container-div')?.getBoundingClientRect()
      let rect = document.getElementById('map-container')?.getBoundingClientRect()
      if (divRect?.top < 10) {
        if(this.mapContainerStyle.position !== 'fixed') {
          this.mapContainerStyle.position = 'fixed'
          this.mapContainerStyle.top = '10px'
          this.mapContainerStyle.bottom = ''
          this.mapContainerStyle.width = (document.getElementById('map-container-div').offsetWidth - 10) + 'px'
        }else if(rect?.bottom > divRect?.bottom) {
          this.mapContainerStyle.top = ''
          this.mapContainerStyle.bottom = (document.documentElement.clientHeight - divRect.bottom) + 'px'
        }else{
          this.mapContainerStyle.top = '10px'
          this.mapContainerStyle.bottom = ''
        }
      } else {
        if(this.mapContainerStyle.position !== '') {
          this.mapContainerStyle.position = ''
          this.mapContainerStyle.width = '100%'
          this.mapContainerStyle.top = ''
          this.mapContainerStyle.bottom = ''
        }
      }
    },
    getRouteQuery(){
      let query = this.$route.query
      query.Keyword = undefined
      return query
    }
  }
}
</script>

<style scoped>

.hotel-list {
  margin-bottom: 20px;
}

.hotel-list .hotel-list-item:hover {
  box-shadow: 2px 2px 8px 0 #e1e2e7;
}

.hotel-list .hotel-list-item-left {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.1);
}

.hotel-list .hotel-list-item-right {
  padding: 10px 10px 10px 15px;
}

.hotel-list .hotel-list-item-right p {
  margin-bottom: 5px;
}

.hotel-list .hotel-name {
  display: flex;align-items: center;justify-content: space-between;
}

.hotel-list .hotel-profile {
  text-overflow: '-o-ellipsis-lastline';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: rgba(0, 0, 0, 0.45);
}

.hotel-list .hotel-button {
  text-align: right;
}

.hotel-list .guestrating {
  background-color: rgba(0, 0, 0, 0.38);
  display: block;
  border-radius: 5px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 2px 5px;
  font-weight: bolder;
  color: #fff;
}

.list-filter-items-span {
  width:70px;
  font-weight: 500;
  display: block;
}

.list-filter-items-title {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.ant-divider-horizontal {
  margin:10px 0;
}

.location-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px 20px;
}
.location-container-item {
  white-space: nowrap;
  min-width: 120px;
}
.location-container-item-1 {
  margin-right: 30px;
}

.more-option-title{
  display: flex;justify-content: space-between;align-items: center;
}
.more-option-content{
  width: 800px;display: flex;max-height: 180px;overflow: auto;flex-wrap: wrap;align-items: center;gap: 8px 20px;
}

</style>