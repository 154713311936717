const StarOptionsCn = [
    {value: '5', label: '五星/豪华'},
    {value: '4', label: '四星/高档'},
    {value: '3', label: '三星/舒适'},
    {value: '2', label: '经济型'},
]

const StarOptionsEn = [
    {value: '5', label: '5 Star/Luxury'},
    {value: '4', label: '4 Star/Premium'},
    {value: '3', label: '3 Star/Comfort'},
    {value: '2', label: 'Economy'},
]

export function getStarOptions(isZHCN) {
    return isZHCN ? StarOptionsCn : StarOptionsEn
}