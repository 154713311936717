const AllChains = [
    {code: 10, name_en: 'Hilton Worldwide', name_cn: '希尔顿酒店集团'},
    {code: 20, name_en: 'Intercontinental Hotels Group', name_cn: '洲际酒店集团'},
    {code: 30, name_en: 'Accor Hotels', name_cn: '雅高酒店集团'},
    {code: 40, name_en: 'Shangri - La', name_cn: '香格里拉酒店集团'},
    {code: 50, name_en: 'Wyndham Hotel Group', name_cn: '温德姆酒店集团'},
    {code: 60, name_en: 'Ascott International', name_cn: '雅诗阁'},
    {code: 70, name_en: 'Kempinski', name_cn: '凯宾斯基'},
    {code: 80, name_en: 'Best Western International', name_cn: '贝斯特韦斯特国际酒店集团'},
    {code: 90, name_en: 'Hyatt Hotels And Resorts', name_cn: '凯悦酒店集团'},
    {code: 100, name_en: 'Four Seasons', name_cn: '四季酒店集团'},
    {code: 110, name_en: 'Radisson Hotel Group', name_cn: '丽笙酒店集团'},
    {code: 120, name_en: 'Oaks Hotels And Resorts', name_cn: '盛橡酒店及度假村'},
    {code: 130, name_en: 'Choice Hotels', name_cn: '精选国际酒店集团'},
    {code: 140, name_en: 'Reddoorz', name_cn: 'RedDoorz'},
    {code: 150, name_en: 'Hoteles Nh', name_cn: 'NH酒店集团'},
    {code: 160, name_en: 'Marriott Hotels And Resorts', name_cn: '万豪国际集团'},
    {code: 170, name_en: 'Posadas', name_cn: 'Posadas'},
    {code: 180, name_en: 'Louvre Hotels', name_cn: 'Louvre Hotels'},
    {code: 190, name_en: 'Barcelo Hoteles', name_cn: '巴塞罗酒店度假村'},
    {code: 200, name_en: 'Melia Hotels International', name_cn: '美利亚酒店集团'},
    {code: 210, name_en: 'Eurostars', name_cn: 'Eurostars'},
    {code: 220, name_en: 'Mantra Group', name_cn: 'Mantra Group'},
    {code: 230, name_en: 'Preferred Hotels & Resorts', name_cn: '璞富腾酒店及度假村'},
    {code: 240, name_en: 'Riu Hoteles', name_cn: '悦宜湾酒店及度假村'},
    {code: 250, name_en: 'City Express', name_cn: 'City Express'},
    {code: 260, name_en: 'Millennium & Copthorne Hotels', name_cn: '千禧国敦酒店集团'},
    {code: 270, name_en: 'Taj Hotels', name_cn: '泰姬酒店集团'},
    {code: 280, name_en: 'Iberostar', name_cn: 'Iberostar'},
    {code: 290, name_en: 'Am Resorts', name_cn: 'Am Resorts'},
    {code: 300, name_en: 'Travelodge Uk', name_cn: 'Travelodge Uk'}
]

export function getChainOptions(isZHCN) {
    if(isZHCN)
        return AllChains.map(item => {
            return { value: item.code, label: item.name_cn }
        })
    else
        return AllChains.map(item => {
            return { value: item.code, label: item.name_en }
        })
}